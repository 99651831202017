<template>
  <b-container>
    <b-card>
      <b-row class="my-3">
        <b-col>
          <h2>Help Topic Search</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form v-on:submit.prevent="search()" action="" ref="helpForm">
            <b-row>
              <b-col cols="12">
                <b-input-group>
                  <b-input
                    v-model="form.keywords"
                    placeholder="Help Topic Search"
                  />
                  <b-input-group-append>
                    <b-button variant="primary" @click="search()"
                      >Search</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      <div v-if="this.searchresults.length > 0" class="mt-5">
        <h5>Search Results</h5>
        <b-row v-for="row in searchresults" :key="row.id">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" class="text-left">
                <a
                  href="#"
                  v-b-modal="'helpanswermodal'"
                  user="'row'"
                  @click="setModalInfo(row)"
                  class="default-answer-link"
                  :no-close-on-backdrop="true"
                >
                  {{ row.question }}
                </a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="searched && searchresults.length === 0" class="mt-5">
        <h5>No Results</h5>
      </div>
      <div class="text-center mt-2" v-if="searched">
        <button class="btn btn-warning" @click.prevent="clear()">
          Clear Search
        </button>
      </div>
    </b-card>
    <br />
    <b-card
      v-if="this.searchresults.length == 0 && !searched && faq.length > 0"
    >
      <b-row class="my-3">
        <b-col>
          <h2>Frequently Asked Questions</h2>
        </b-col>
      </b-row>
      <b-row v-for="row in faq" :key="row.id">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" class="text-left">
              <a
                href="#"
                v-b-modal="'helpanswermodal'"
                user="'row'"
                @click="setModalInfo(row)"
                class="default-answer-link"
              >
                {{ row.question }}
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <b-card
      v-if="this.searchresults.length == 0 && !searched && videos.length > 0"
    >
      <b-row class="my-3">
        <b-col>
          <h2>How-To Videos</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="row in videos" :key="row.id" cols="4">
          <div class="thumbpad">
            <a
              href="#"
              v-b-modal="'helpanswermodal'"
              user="'row'"
              @click="setModalInfo(row)"
              class="default-video-link"
            >
              <div class="thumb-border">
                <b-embed type="video" :src="row.answers[0].url"></b-embed>
              </div>
              {{ row.question }}
            </a>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="helpanswermodal"
      :no-close-on-backdrop="true"
      :title="modalinfo.question"
      size="xl"
      ok-only
      ok-variant="success"
    >
      <b-row>
        <b-col cols="12" v-for="a in modalinfo.answers" :key="a.answerid">
          <div v-if="a.content.length > 0" v-html="a.content"></div>
          <div v-if="a.answertype == 'Help Screenshot'" class="text-center">
            <img :src="a.url" />
          </div>
          <div v-if="a.answertype == 'Help Video'">
            <b-embed type="video" aspect="16by9" controls allowfullscreen>
              <source :src="a.url" type="video/mp4" />
            </b-embed>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
// import UserService from '@/services/UserService'
import HelpService from '@/services/HelpService'

export default {
  data () {
    return {
      form: {
        keywords: null
      },
      searchresults: [],
      searched: false,
      faq: [],
      videos: [],
      modalinfo: {},
      loading: false
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      this.$updateloading(1)
      HelpService.getDefaultHelp(this.form).then(
        (response) => {
          this.faq = response.data.info.faq
          this.videos = response.data.info.videos
        }
      ).finally(() => {
        this.$updateloading(-1)
        this.loading = false
      })
    },

    setModalInfo (row) {
      this.modalinfo = row
    },

    search () {
      if (this.form.keywords === null || this.form.keywords === '') {
        this.searchresults = []
      } else {
        this.loading = true
        this.$updateloading(1)
        HelpService.search(this.form).then(
          (response) => {
            this.searchresults = response.data.info
            this.searched = true
          }
        ).finally(() => {
          this.$updateloading(-1)
          this.loading = false
        })
      }
    },

    clear () {
      this.searched = false
      this.searchresults = []
      this.form.keywords = ''
    }
  }
}
</script>

<style scoped>
.search-answer-link {
  display: block;
  color: black;
}
.default-answer-link {
  display: block;
  color: black;
  margin: 5px 0 5px 0;
}
.default-video-link {
  color: black;
  margin: 5px 0 5px 0;
}
.thumbpad {
  margin: 10px;
  padding: 10px;
  text-align: left;
}
.thumb-border {
  border: 1px solid black;
  margin-bottom: 5px;
}
</style>
